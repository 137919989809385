var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { attrs: { id: "map" } }),
    _c("div", { staticClass: "sticky table-info px-2 py-3" }, [
      _c("h5", { staticClass: "mb-0" }, [
        _vm._v("Showing " + _vm._s(_vm.total) + " restaurants"),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }